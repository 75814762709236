<template>
  <Layout>
    <div class="container">
      <div class="is-clearfix">
        <button v-on:click="$router.go(-1)" class="button is-pulled-left">
          <span class="icon">
            <icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>Back</span>
        </button>
      </div>
      <div class="is-divider"></div>
      <div class="columns">
        <div class="column">
          <p>Updated at: {{logData.modified}}</p>
          <p>Action: {{logData.action}}</p>
          <p>Prefix ID: {{logData.initialState.sequentialId}}</p>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-one-thirds" v-if="recordInitial && recordInitial.fields">
          <tabs>
            <tab
              v-for="(tab, index) in formsInitial"
              :key="tab._id"
              @click.native="selectedForm = index"
            >{{tab.title}}</tab>
          </tabs>
          <p
            class="subtitle"
            v-if="logData && logData.formId"
          >Form: {{ `${logData.formId.title } - ${logData.formId.prefixId}`}}</p>
          <p class="subtitle">Previous record</p>
          <div
            class="record-line initial"
            :class="setColorClass(index)"
            v-for="(field, index) in recordInitial.fields"
            :key="field.id"
          >
            <p>
              <strong>{{ field.label }}</strong>
            </p>
            <p v-if="field.value && field.value.url">
              <a :href="field.value.url">
                <span v-if="field.value.name.includes('.pdf')">{{ field.value.name }}</span>
                <img :src="field.value.url" :alt="field.value.name" v-else />
              </a>
            </p>
            <p v-else>{{ field.value || 'No answer' }}</p>
          </div>
        </div>
        <div class="column is-one-thirds" v-if="(logData.action === 'Update') && recordInitial">
          <tabs>
            <tab
              v-for="(tab, index) in formsFinal"
              :key="tab._id"
              @click.native="selectedForm = index"
            >{{tab.title}}</tab>
          </tabs>
          <p
            class="subtitle"
            v-if="logData && logData.formId"
          >Form: {{ `${logData.formId.title } - ${logData.formId.prefixId}`}}</p>
          <p class="subtitle">Final record</p>
          <div
            class="record-line final"
            :class="setColorClass(index)"
            v-for="(field, index) in recordFinal.fields"
            :key="field.id"
          >
            <p>
              <strong>{{ field.label }}</strong>
            </p>
            <p v-if="field.value && field.value.url">
              <a :href="field.value.url">
                <span v-if="field.value.name.includes('.pdf')">{{ field.value.name }}</span>
                <img :src="field.value.url" :alt="field.value.name" v-else />
              </a>
            </p>
            <p v-else>{{ field.value || 'No answer' }}</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      logData: {},
      selectedForm: null
    };
  },
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  methods: {
    setColorClass(index) {
      if (this.logData && this.logData.action === 'New form') {
        return 'new';
      } else if (this.logData && this.logData.action === 'Update') {
        if (
          !this.compareValues(
            this.formsInitial[this.selectedForm].fields[index].value,
            this.formsFinal[this.selectedForm].fields[index].value
          )
        ) {
          return 'updated';
        }
      } else {
        return 'deleted';
      }
      return '';
    },
    compareValues(value1, value2) {
      const type1 = Object.prototype.toString.call(value1);
      const type2 = Object.prototype.toString.call(value2);
      if (
        type1 === type2 &&
        type1 === '[object Array]' &&
        value1.length === value2.length
      ) {
        value1.forEach((el, index) => {
          if (el !== value2[index]) {
            return false;
          }
        });
        return true;
      } else if (value1 === value2) {
        return true;
      }
      return false;
    }
  },
  computed: {
    formsInitial() {
      const tabs = [];
      if (this.logData && this.logData.initialState) {
        tabs.push({
          title: this.logData.formId.title,
          ...this.logData.initialState
        });
      }
      if (
        this.logData &&
        this.logData.initialState &&
        this.logData.initialState.linkedForms &&
        this.logData.initialState.linkedForms.length
      ) {
        this.logData.initialState.linkedForms.forEach(form => {
          tabs.push(form);
        });
      }
      return tabs;
    },
    formsFinal() {
      const tabs = [];
      if (this.logData && this.logData.finalState) {
        tabs.push({
          title: this.logData.formId.title,
          ...this.logData.finalState
        });
      }
      if (
        this.logData &&
        this.logData.finalState &&
        this.logData.finalState.linkedForms &&
        this.logData.finalState.linkedForms.length
      ) {
        this.logData.finalState.linkedForms.forEach(form => {
          tabs.push(form);
        });
      }
      return tabs;
    },
    recordInitial() {
      return this.formsInitial[this.selectedForm];
    },
    recordFinal() {
      return this.formsFinal[this.selectedForm];
    }
  },
  async created() {
    const logId = this.$route.params.id;
    if (logId) {
      try {
        const uriLog = $formsConfig.core.api.log.replace('__logId__', logId);
        const logResponse = await axios.get(uriLog);
        this.logData = logResponse.data;
        this.formInitial = this.logData.initialState;
        this.selectedForm = 0;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  > .columns {
    height: 100%;
    margin-top: 0;
    > .column {
      height: 100%;
    }
  }
}
.headers {
  font-size: 14px;
  font-weight: bold;
  color: $tittle-font;
}
.new {
  background-color: #e6ffed;
}
.updated {
  background-color: #ffeef0;
}
.final.updated {
  background-color: #e6ffed;
}
.deleted {
  background-color: #ffeef0;
}
// .new.label {
//   background-color: #cdffd8;
// }
</style>
